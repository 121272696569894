html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(135deg, rgb(243, 107, 55) 0%, rgb(243, 107, 55) 23%,rgb(244, 133, 60) 23%, rgb(244, 133, 60) 27%,rgb(245, 159, 64) 27%, rgb(245, 159, 64) 73%,rgb(245, 186, 69) 73%, rgb(245, 186, 69) 74%,rgb(246, 212, 73) 74%, rgb(246, 212, 73) 78%,rgb(247, 238, 78) 78%, rgb(247, 238, 78) 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.btn {
  background: #f36b37;
  color:white;
  border-color: transparent;
}

.btn-primary:hover {
  background: #f3581b;
  border-color: #f3581b;
}

.main-img {
  padding-top: 4rem;
  height: 100%;
  background: url(img/background.jpg) no-repeat center center fixed;
  background-size: cover;
}

.padding-top-sm {
  padding-top: .7em;
}

.padding-top-md {
  padding-top: 3em;
}

.padding-top-bg {
  padding-top: 2em;
}

@media (min-width: 450px) {
  .padding-top-bg {
    padding-top: 4.5em;
  }
}

.margin-top-xs {
  margin-top: 0.4em;
}

.margin-top-sm {
  margin-top: 1.8em;
}

.margin-top-md {
  margin-top: 2.5em;
}

.color-white {
  color: white;
}

.color-black {
  color: black;
}

.color-main {
  color: rgb(245, 159, 64)
}

.text-md {
  font-size: 0.8em;
}

.text-lg {
  font-size: 1.4em;
}

.margin-left-sm {
  margin-left: 0.8em;
}

.title {
  font-size: 2.0rem;
  font-weight: 100;
  text-align: center;
}

@media (min-width: 490px) {
  .text-lg {
    font-size: 2em;
  }

  .title {
    font-size: 2.6em;
  }
}

.playlist a:hover {
  color: rgb(245, 159, 64)
}

.playlist img {
  max-width: 250px;
}

@media (min-width: 450px) {
  .playlist img {
    max-width: 300px;
  }
}

.text-thicc {
  font-weight: 400;
}

.brand {
  font-size: 1.8em;
  font-weight: 100;
  padding-right: 1em;
  border-right: 1px solid black;
}

.nav-link {
  font-size: 1.4em;
  font-weight: 400;
}

.text-sm {
  font-size: 1.2em;
}

.text-bg {
  font-size: 4em;
}

@media (min-width: 480px) {
  .text-bg {
    font-size: 5.8em;
  }
}

.top-padding {
  padding-top: 4rem;
}

.center {
  text-align: center;
}

.font-weight-lt {
  font-weight: 100;
}

.form-container {
  background: white;
  padding: 20px;
  border-radius: 20px;
  margin-top: 2rem;
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  min-height: 60vh;
}

.sub-header {
  width: 55%;
  margin: 0 auto;
}

@media (min-width: 480px) {
  .sub-header {
    width: 55;
  }
}
