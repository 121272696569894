.App {
  height: 100%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-body {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spotifyButton{
  width: 100px;
  display: inline-block;
  background-color: black;
  color: white !important;
  min-width: 250px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  border-radius: 30px !important;
  padding: 17px 35px;
  margin-top: 20px;
  margin-bottom: 20px;
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none;
}

.buttonWrapper :hover {
  transform: scale(1.05) perspective(1px)
}