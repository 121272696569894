.mr-auto a svg {
    fill: rgba(0,0,0,.5);
    transition: fill .2s ease-in-out;
}

.navbar {
    position: fixed;
    width: 100%;
    top: 0px;
}
.mr-auto {
    text-align: center;
}

.mr-auto a {
    transition: fill .2s ease-in-out;
}
.mr-auto a :hover {
    fill: #fee060;
    transition: fill .2s ease-in-out;
}

.navbar-light .navbar-nav .nav-link {
    transition: color .2s ease;
    transition-property: color;
    transition-duration: .2s;
    transition-timing-function: ease;
    transition-delay: 0s;
}

.navbar-light :hover {
    color: #fee060 !important;
    transition: color .2s ease;
    transition-property: color;
    transition-duration: .2s;
    transition-timing-function: ease;
    transition-delay: 0s;
    text-decoration:none;
}
.navbar-brand {
    color: rgb(124, 124, 125);
    border-right: 1px solid #f59f40;
}